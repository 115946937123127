<template>
  <additional-kol
      :additional="false"
      @prevStep="$emit('prevStep')"
      @nextStep="$emit('nextStep', $event)"
  />
</template>

<script>
import AdditionalKol from "./AdditionalKol";

export default {
  name: "step2",
  data() {
    return {};
  },
  components: {
    AdditionalKol,
  },
};
</script>
