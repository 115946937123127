<template>
  <div class="tabWrap">
    <div class="btnList">
      <div style="flex: 1">
        <el-button size="mini" type="primary" @click="btnAddKol"
        >添加KOL
        </el-button>
        <el-button size="mini" type="primary" @click="fileAdd"
        >文件添加
        </el-button>
      </div>
      <!-- <list-version /> -->
      <el-button
          v-if="additionalKol"
          style="margin-left: 20px"
          type="primary"
          size="mini"
          @click="addAllToActivity"
      >全部KOL加入活动
      </el-button>
    </div>
    <div class="colTab">
      <el-tabs type="border-card" v-model="tabActiveName" @tab-click="tabClick">
        <el-tab-pane label="在库KOL" name="first">
          <div class="mainWrap">
            <!--区分是在库的还是追加中的数量-->
            <sidebar
                style="width: 120px; margin-left: -15px"
                :show-kol-number="true"
                :kol-number-data="kolNumber.kolAmount"
                size="mini"
            />
            <div class="colContent">
              <ranking-list
                  style="width: 100%"
                  :show-list-version="false"
                  :show-side-bar="false"
                  :showHandle="true"
                  :collectionId="collectionId"
                  size="mini"
                  :colEmpty="colEmpty"
                  @addToActivity="$emit('addToActivity', $event)"
                  @refreshKolNumber="refreshKolNumber"
              />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="追加中KOL" name="second">
          <div class="mainWrap">
            <sidebar
                style="width: 120px; margin-left: -15px"
                :show-kol-number="true"
                :kol-number-data="kolNumber.nonKolAmount"
                size="mini"
            />
            <div class="colContent">
              <div
                  style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 10px;
                "
              >
                <el-radio-group v-model="statusRadio" @change="handleStatus">
                  <el-radio :label="0">全部</el-radio>
                  <el-radio :label="2">成功</el-radio>
                  <el-radio :label="1">进行中</el-radio>
                  <el-radio :label="3">失败</el-radio>
                </el-radio-group>
                <el-button
                    size="mini"
                    :loading="downLoading"
                    @click="statusDownload"
                    type="primary"
                >追加KOL状态下载
                </el-button>
              </div>
              <div>
                <el-table
                    v-loading="noInterTableLoading"
                    :data="tableInfo"
                    :header-cell-style="headerStyle"
                    :cell-style="cellStyle"
                >
                  <el-table-column
                      v-for="item in colData"
                      :key="item.prop"
                      align="center"
                      :width="item.width || ''"
                      :prop="item.prop"
                      :label="item.label"
                      :show-overflow-tooltip="true"
                      :label-class-name="item.canRank ? 'colActClass' : ''"
                      :class-name="item.canRank ? 'colActClass' : ''"
                  >
                    <template #header>
                      <span>
                        {{ item.label }}
                      </span>
                    </template>
                    <template #default="scoped">
                      <a
                          style="color: blue"
                          target="_blank"
                          v-if="item.label === 'KOL链接'"
                          :href="scoped.row.url"
                      >{{ scoped.row.url }}</a
                      >
                      {{ item.prop === "url" ? "" : scoped.row[item.prop] }}
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="状态" width="100">
                    <template #default="{ row }">
                      <el-popover
                          v-if="row.status === 3"
                          trigger="hover"
                          placement="top"
                      >
                        <p>{{ row.failReason }}</p>
                        <template #reference>
                          <div class="name-wrapper">
                            <el-tag type="danger" size="medium">失败</el-tag>
                          </div>
                        </template>
                      </el-popover>
                      <div v-else>
                        <el-tag
                            :type="row.status === 1 ? 'warning' : 'success'"
                            size="medium"
                        >
                          {{ row.status === 1 ? "进行中" : "成功" }}
                        </el-tag>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="articlePaginationBox">
                  <el-pagination
                      :current-page="taskCurrentPage"
                      :page-size="taskPageSize"
                      :total="taskTotal"
                      @current-change="handleTaskCurrentChange"
                      layout="total, prev, pager, next, jumper"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--文件添加-->
    <el-dialog
        title="文件添加"
        @close="fileDialogClose"
        v-model="fileAddDialog"
        width="650px"
    >
      <el-upload
          style="text-align: center"
          drag
          :data="addFileCollectionIdObj"
          ref="upload"
          :action="addKolaction"
          :file-list="kolFileList"
          :headers="{ token }"
          :accept="accept"
          :limit="limit"
          :before-upload="fileBeforeUpload"
          :on-success="fileOnSuccess"
          :on-error="fileOnError"
          multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <template #tip>
          <div class="el-upload__tip fileHeight">
            <span style="color: red; margin-top: 5px"
            >* 只能上传xls, xlsx文件，且不超过30M</span
            >
            <div style="margin: 2px 0 5px 0">
              <el-checkbox v-model="isAddNoKol"
              >追加文件中未在库的KOL项
              </el-checkbox>
            </div>
            <div style="margin-top: 10px">
              <a
                  class="downloadBtn"
                  href="https://omnivoice-kol.oss-cn-hangzhou.aliyuncs.com/shiseido/omniVoice_manualForAddKol.pdf"
              >
                点击下载KOL规范文档
              </a>
              <a
                  class="downloadBtn"
                  href="https://omnivoice-kol.oss-cn-hangzhou.aliyuncs.com/shiseido/fileMasterplate.xlsx"
              >文件模版下载</a
              >
              <span>2020.2.10更新</span>
            </div>
          </div>
        </template>
      </el-upload>
      <template #footer>
        <div>
          <el-button @click="cancelAddFile">取 消</el-button>
          <el-button
              :disabled="btnDisAble"
              type="primary"
              @click="addFileSubmit"
          >确 定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import sidebar from "@/components/common/socialPlatform";

import RankingList from "../RankListV2";
// import listVersion from "@/components/common/listVersion";

import { mapGetters, mapMutations } from "vuex";

import {
  getNoKol,
  addFileSubmit,
  getCollectionInfo,
  downloadKolUploadTemplate,
  downloadKolfile,
  downloadKolStatus,
} from "@/api/collection";

export default {
  name: "CollectDetail",
  data() {
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      tabActiveName: "first",
      kolNumber: { kolAmount: {}, nonKolAmount: {} },

      tableInfo: [],
      colData: [
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "名称",
          order_id: 1,
          prop: "name",
          round: null,
          type: "String",
          width: "150",
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "KOL ID",
          order_id: 1,
          prop: "kolId",
          round: null,
          type: "String",
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "KOL链接",
          order_id: 1,
          prop: "url",
          round: null,
          type: "String",
          width: "180",
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "上传时间",
          order_id: 1,
          prop: "uploadTime",
          round: null,
          type: "String",
          width: "200",
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "完成时间",
          order_id: 1,
          prop: "finishTime",
          round: null,
          type: "String",
          width: "200",
        },
      ],
      taskCurrentPage: 1,
      taskTotal: 0,
      taskPageSize: 10,

      // 文件上传数据
      fileAddDialog: false,
      addKolaction: process.env.VUE_APP_API_HOST + "/collection/manage/kol/file",
      addFileCollectionIdObj: {
        collectionId: this.collectionId,
      },
      kolFileList: [],
      accept: ".xls,.xlsx",
      limit: 1,
      isAddNoKol: true,
      filePath: "",
      btnDisAble: true,

      noInterTableLoading: true,
      statusRadio: 0,
      downLoading: false,
    };
  },
  props: {
    collectionId: {
      default: 0,
    },
    additionalKol: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "month",
      "crowdList",
      "token",
      "colSiteValue",
      "listSearchWord",
      "listSearchType",
      "listVersion",
    ]),
    statusName() {
      switch (this.statusRadio) {
        case 0:
          return "全部";
        case 1:
          return "进行中";
        case 2:
          return "成功";
        case 3:
          return "失败";
        default:
          return "全部";
      }
    },
    // 判断收藏夹是否为空
    colEmpty() {
      return Object.values(this.kolNumber.kolAmount).every((num) => num === 0);
    },
  },
  mounted() {
    if (this.collectionId) {
      this.getCollectionInfo();
    }
  },
  beforeRouteLeave(to, from, next) {
    // setIsAddClass: "SET_ISADDCLASS",
    this.setIsAddClass(false);
    next();
  },
  methods: {
    ...mapMutations({
      setColTargetCrowd: "SET_COLTARGETCROWD",
      setIsAddClass: "SET_ISADDCLASS",
    }),
    refreshKolNumber(amount) {
      this.kolNumber = amount;
    },
    async getCollectionInfo() {
      let { data } = await getCollectionInfo({
        collectionId: this.collectionId,
      });
      this.kolNumber = {
        kolAmount: data.kolAmount,
        nonKolAmount: data.nonKolAmount,
      };
      // 设置目标人群
      const crowd = this.crowdList[0].crowdList.find(
          (v) => v.key === data.crowd
      );
      this.setColTargetCrowd(crowd);
    },
    tabClick(tab) {
      if (tab.paneName === "second") {
        this.getNoKol();
      }
    },
    // 添加kol
    btnAddKol() {
      this.$router.push({
        name: "AddKol",
        query: { collectionId: this.btoa(this.collectionId) },
      });
    },
    // 文件添加
    fileAdd() {
      this.fileAddDialog = true;
    },
    fileBeforeUpload(file) {
      let reg = new RegExp("^[^!@#$%^&*<>+]{0,}$");
      if (!reg.test(file.name)) {
        this.$message({
          message: "文件名包含特殊字符，特殊字符包含（^!@#$%^&*<>+）",
          type: "warning",
        });
        return false;
      }
      const sizeLimit = 1024 * 1024 * 30;
      if (file.size > sizeLimit) {
        this.$message({
          message: "文件不能大于30M",
          type: "warning",
        });
        return false;
      } else {
        return true;
      }
    },
    fileOnSuccess(res) {
      if (res.code === 203) {
        this.$message({
          message: "文件格式错误,请参考规范文档！",
          type: "error",
          duration: 5000,
        });
        this.$refs.upload.clearFiles();
      } else if (res.code === 204) {
        this.$message({
          message: "文件解析失败,请查看模版文件!",
          type: "error",
          duration: 5000,
        });
        this.$refs.upload.clearFiles();
      } else if (res.code === 205) {
        this.$message({
          message: "添加KOL超额，请联系管理员!",
          type: "error",
          duration: 5000,
        });
        this.$refs.upload.clearFiles();
      } else if (res.code === 0) {
        this.filePath = res.data;
        this.btnDisAble = false;
      } else if (res.code === 3001) {
        this.$message({
          message: "单个收藏夹最多添加1000个KOL",
          type: "error",
          duration: 5000,
        });
      } else {
        this.$message({
          message: "上传文件失败！",
          type: "error",
          duration: 5000,
        });
        this.$refs.upload.clearFiles();
      }
    },
    fileOnError() {
      this.$refs.upload.clearFiles();
      this.$message.error("上传文件失败!");
    },
    async addFileSubmit() {
      let params = {
        collectionId: this.collectionId,
        filepath: this.filePath,
        addKol: this.isAddNoKol ? 1 : 0,
      };
      let res = await addFileSubmit(params);
      if (res.code === 0) {
        this.$message({
          message: "上传文件成功！",
          type: "success",
        });
      } else {
        this.fileOnError();
      }
      this.$refs.upload.clearFiles();
      this.fileAddDialog = false;
    },
    cancelAddFile() {
      this.$refs.upload.clearFiles();
      this.fileAddDialog = false;
    },
    fileDialogClose() {
      this.isAddNoKol = true;
    },
    downloadKolUploadTemplate() {
      downloadKolUploadTemplate();
    },
    downloadKolfile() {
      downloadKolfile();
    },

    // 获取追加中kol
    async getNoKol(flag = true) {
      this.noInterTableLoading = true;
      let params = {
        collectionId: this.collectionId,
        platform: this.colSiteValue,
        page: flag ? 1 : this.taskCurrentPage,
        size: this.taskPageSize,
        sortColumn: "create_time",
        sortDirection: "desc",
        status: this.statusRadio - 0,
      };
      this.taskCurrentPage = params.page;
      let res = await getNoKol(params);
      if (res.code === 0) {
        this.tableInfo = res.data.table;
        this.taskTotal = res.data.total;
        this.noInterTableLoading = false;
      }
    },
    // 追加中方法
    handleTaskCurrentChange(page) {
      this.taskCurrentPage = page;
      this.getNoKol(false);
    },
    statusDownload() {
      this.downLoading = true;
      let params = {
        collectionId: this.collectionId,
        status: this.statusRadio,
      };
      this.$message({
        message: "数据正在导出，请不要刷新切换页面，耐心等待!",
        type: "info",
      });
      downloadKolStatus(params, `追加KOL${this.statusName}数据.xlsx`).then(
          () => {
            this.$message({
              message: "正在导出",
              type: "success",
            });
            this.downLoading = false;
          }
      );
    },
    handleStatus() {
      this.getNoKol();
    },
    // 追加kol时，添加当前收藏夹下所有的kol到展开列表中
    addAllToActivity() {
      this.$emit("addAllToActivity");
    },
  },
  watch: {
    colSiteValue() {
      if (this.tabActiveName === "second") {
        // 追加中kol
        this.getNoKol();
      }
    },
    collectionId() {
      this.getCollectionInfo();
    },
  },
  components: {
    sidebar,
    RankingList,
    // listVersion,
  },
};
</script>

<style lang="scss">
.tabWrap {
  .downloadBtn {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    margin-right: 10px;

    &:hover {
      color: #409eff;
      border-color: #c6e2ff;
      background-color: #ecf5ff;
    }
  }

  .el-upload-list.el-upload-list--text {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translateX(-80%);
  }

  .el-tabs {
    .el-tabs__content {
      padding-bottom: 0;
      padding-right: 5px;
    }
  }
}
</style>

<style scoped lang="scss">
.tabWrap {
  height: 100%;
  background-color: #fff;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;

  .btnList {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .colTab {
    flex: 1;
    padding-top: 13px;

    .mainWrap {
      display: flex;

      .colContent {
        flex: 1;
        margin-left: 5px;
      }

      .articlePaginationBox {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
.fileHeight{
  margin-top:50px;
}
</style>
