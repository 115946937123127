<template>
  <div class="wrapper">
    <div class="arrow_div">
      <div class="arrow-block">
        <div :key="index" v-for="(item,index) in progress" >
          <div  class="arrow" :class="{'pass':index < currentStep,'ing':index === currentStep}">
            <svg-icon
                v-if="index< currentStep"
                icon-class="passIcon"
                class-name="icon"
                style="font-size:20px;"
            ></svg-icon>
            <svg-icon
                v-else-if="index === currentStep"
                icon-class="ingIcon"
                class-name="icon"
            ></svg-icon>
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <step1
        :isSetDateFormat="isSetDateFormat"
        v-show="currentStep === 0"
        @nextStep="step1NextStep"
    />
    <step2
        v-show="currentStep === 1"
        @prevStep="rebackToStep1"
        @nextStep="step2NextStep"
    />
    <step3
        v-show="currentStep === 2"
        @prevStep="currentStep = 1"
        @submitCreatCampaign="submitCreatCampaign"
    />
  </div>
</template>

<script>
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import { creatCampaign } from "@/api/campaignTrace";
import { mapMutations } from "vuex";

export default {
  name: "createCampaign",
  data() {
    return {
      currentStep: 0,
      step1Params: {},
      step2Params: {},
      isSetDateFormat: false,
      progress:['添加活动基本信息','选择活动KOL','填写合作供应商'],
    };
  },
  beforeRouteLeave(to, from, next) {
    this.setIsAddClass(false);
    next();
  },
  methods: {
    ...mapMutations({
      setIsAddClass: "SET_ISADDCLASS",
    }),
    step1NextStep(params) {
      this.step1Params = params;
      this.currentStep = 1;
    },
    step2NextStep(params) {
      this.step2Params = params;
      this.currentStep = 2;
    },
    rebackToStep1() {
      this.currentStep = 0;
      this.isSetDateFormat = true;
      setTimeout(() => {
        this.isSetDateFormat = false;
      }, 100);
    },
    // 创建活动
    async submitCreatCampaign(params) {
      let campaignInfo = {
        ...params,
        ...this.step1Params,
        ...this.step2Params,
      };
      let res = await creatCampaign(campaignInfo);
      if (res.code === 0) {
        this.$message({
          type: "success",
          message: "创建活动成功!",
        });
        this.$router.push({ name: "campaignTrace" });
      } else if (res.code === 201) {
        console.log("非法请求参数");
      } else if (res.code === 202) {
        console.log("已存在活动名称");
      } else if (res.code === 203) {
        this.$message({
          type: "error",
          message: "创建活动失败，请联系管理员！",
        });
      } else if (res.code === 204) {
        this.$message({
          type: "error",
          message: "删除活动失败，请联系管理员！",
        });
      } else if (res.code === 205) {
        console.log("更新活动中标供应商id失败");
      } else if (res.code === 206) {
        console.log("修改活动KOL信息失败");
      } else if (res.code === 207) {
        this.$message({
          type: "info",
          message: "活动kol列表不能为空",
        });
      } else {
        this.$message({
          type: "error",
          message: "创建活动失败,请联系管理员",
        });
      }
    },
  },
  components: {
    Step1,
    Step2,
    Step3,
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  .arrow-block{
    display: flex;
    gap:0;
    justify-content: flex-start;
    width:100%;
  }
  .arrow-block>div{
    flex:1;
    display: flex;
    align-items:center;
    justify-content: flex-start;
    flex-direction: column;
  }
  .arrow {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 48px; /* Adjust the height as needed */
    position: relative;
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    background: rgba(241, 241, 241, 1);
    text-align: center;
    padding-left:25px;

  }
  .arrow-block>div:nth-child(1) .arrow{
    //border-radius: 23px 0 0 23px;
  }
  .arrow-block>div:last-child .arrow:after,
  .arrow-block>div:last-child .arrow:before{
    content:none;
  }
  .arrow-block>div:last-child .arrow{
    border-radius: 0 23px 23px 0;
  }
  .arrow:before {
    content: '';
    position: absolute;
    right: -24px;
    width: 0;
    height: 0;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 24px solid #fff;
    z-index:2;
  }

  .arrow:after {
    content: '';
    position: absolute;
    right: -22px;
    width: 0;
    height: 0;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 24px solid rgba(241, 241, 241, 1);
    z-index:3;
  }
  .arrow.pass{
    color:#fff;
    background: #7EB992;
  }
  .arrow.pass:after{
    border-left: 24px solid #7EB992;
  }
  .arrow.ing{
    color:#fff;
    background: #4F79BF;
  }
  .arrow.ing:after{
    border-left: 24px solid #4F79BF;
  }
  .arrow .icon{
    font-size:16px;
    margin-right:2px;
    display: flex;
    align-items: center;
    margin-top:2px;
  }
  .arrow_div{
    padding:20px;
    box-shadow: 0 5px 10px rgba(231, 35, 35, 0.17);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom:40px;
  }
  .arrow .circle{
    border:1px solid #666;
    border-radius: 50%;
    width:14px;
    height:14px;
    font-size:12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:2px;
  }
  .newLineDiv {
    height: 60px;
    border: 1px solid #eee;
    border-top: none;
    margin-bottom: 20px;
    position: relative;

    .el-button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
